import * as Actions from "../actions/actions_rutas";

const initialState = {
    rutas: null,
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_RUTA: {
            return {
                ...state,
                rutas: action.retornar,
            };
        }
        default:
            return state;
    }
};

export default reducer;
