import * as Actions from "../actions";

const initialState = {
    entities: [],
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_CONTACTO_GENERAL_MENU_FOOTER: {
            return {
                ...state,
                loading: true,
            };
        }

        case Actions.GET_CONTACTO_GENERAL_MENU_FOOTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                entities: action.payload,
            };
        }
        case Actions.GET_CONTACTO_GENERAL_MENU_FOOTER_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
