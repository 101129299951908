import {combineReducers} from 'redux';
import contactogeneralmenufooterweb from './reducer';
import rutasWeb from './reducer_rutas';

const reducer = combineReducers({
    contactogeneralmenufooterweb,
    rutasWeb,
});

export default reducer;
